.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.white-content {
  background: #e4e4e4;
}

.title {
  padding-bottom: 30px;
  padding-top: 180px;
  color: #a5adae;
  font-size: calc(10px + 3vmin);
}

.mainStyle {
  font-family: Rubik;
  font-size: calc(8px + 1.5vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20;
  text-align: center;
}

.blob {
  position: absolute;
  top: 0;
  left: 0;
  fill: #d36135;
  width: 50vmax;
  z-index: 0;
  animation: move 10s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes move {
  0% {
    transform: scale(1) translate(10px, -30px);
  }
  38% {
    transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
  }
  40% {
    transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
  }
  78% {
    transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
  }
  80% {
    transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
  }
  100% {
    transform: scale(1) translate(10px, -30px);
  }
}

.darkSwitch {
  width: 100%;
  margin: 0;
  background-color: transparent;
  position: absolute;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  top: 4.5%;
  right: 1.5rem;
  float: right;
  align-items: flex-end;
}

.divEdu {
  font-family: Rubik;
  font-size: calc(8px + 1vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.divAbout {
  font-family: Rubik;
  font-size: calc(8px + 1vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 25px;
  text-align: center;
  padding-top: 80px;
}

.divProjects {
  font-family: Rubik;
  font-size: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.divHome {
  font-family: Rubik;
  font-size: calc(8px + 1vmin);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20;
  text-align: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.contactInput {
  font-family: Rubik;
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: auto;
  box-sizing: border-box;
}

.contactSubmit {
  font-family: Rubik;
  width: 80%;
  background-color: #d36135;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.topButtonImg {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.project-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 20px;
  justify-content: space-between;
}

.project-card:hover {
  background-color: #ebebeb;
}

.project-card__image {
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 100%;
}

.project-card__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.project-card__description {
  font-size: 16px;
  margin-bottom: 10px;
}

.project-card__technologies {
  font-size: 14px;
  margin-bottom: 10px;
}

.project-card__links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-gap: 30px;
  padding: 0 30px;
}
